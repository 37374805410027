import {
  clienteLogueado,
  datosUsuario,
  loginWithEmail,
  logout,
} from 'firebase/client';
import Image from 'next/image';
import { useState, useEffect, useContext } from 'react';
import styles from 'styles/Login.module.scss';
import firebase from 'firebase';
import { AdminContext } from 'Context';

export default function Login() {
  const [login, setLogin] = useState({ email: '', pass: '' });
  const [error, setError] = useState(false);

  let { usuarioLogueado, setUsuarioLogueado, router } =
    useContext(AdminContext);

  const handleChange = (e) => {
    setLogin({ ...login, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    loginWithEmail(login.email, login.pass).then((res) => {
      if (res === false) {
        setError(true);
      } else {
        setError(false);
        datosUsuario(res.uid).then((respuesta) => {
          if (respuesta.rol === 'superadmin' || respuesta.rol === 'admin' || respuesta.rol === 'god' || respuesta.rol === 'supergod') {
            setUsuarioLogueado(true);
            router.push(`/${respuesta.pais}/pedidos`);
          } else {
            logout();
            setUsuarioLogueado(false);
          }
        });
      }
    });
  };

  return (
    !usuarioLogueado && (
      <div className={styles.login}>
        <div className={styles.logo}>
          <Image
            src='/imgs/logo.svg'
            layout='responsive'
            width={500}
            height={300}
            alt='logo'
          />
        </div>
        <form onSubmit={handleSubmit}>
          <input
            type='text'
            name='email'
            placeholder='email'
            onChange={handleChange}
          />
          <input
            type='password'
            name='pass'
            placeholder='password'
            onChange={handleChange}
          />
          {error && (
            <p>Error de autenticación, verifica los datos de ingreso.</p>
          )}
          <button>Ingresar</button>
        </form>
      </div>
    )
  );
}
